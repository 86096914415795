.sidebar .nav .nav-item .nav-link > div {
  border-radius: 10px;
  height: 65px;
  width: 65px;
  line-height: 65px;
}
.sidebar .nav .nav-item .nav-link.active > div {
  background-color: #FFC848;
  color: #000000;
}
.sidebar .nav.bottom .nav-link > div {
  height: auto;
  min-height: 65px;
  line-height: 1;
}

.badge.indicator.high > svg {color: #FF5348;}
.badge.indicator.nothing > svg {color: #53E7B1;}
.badge.indicator.moderate > svg {color: #FFC848;}

.zoom-container {
  padding-top: 0 !important;
}

.modal-content, .modal-body {
  background-color: #0C2030;
  color: #fff;
  border-radius: 20px;
}

.modal-body {
  padding: 1.25rem;
}

.modal-body .close {
  color: #fff;
}

.modal-body h3, .modal-body h4, .modal-body h5 {
  color: #fff !important;
}

.img-thumbnail {
  background-color: transparent;
}

.img-large {
  background-color: transparent;
  border-radius: 0.45rem;
  max-width: 100%;
  height: auto;
}

.enhance-form {
  background-color: #0C2030;
  border-radius: 0.45rem;
}

.superscript {
  vertical-align: super;
  font-size: 60%;
  font-weight: normal;
}

.table {
  color: white;
}

.table td:hover, .table tr:hover {
  color: #F0B93B;
}

.table td:not(:first-child), .table th:not(:first-child) {
  text-align: center;
}

.table th, .table td {
  padding: 0.4rem 0.75rem;
  vertical-align: top;
  border-top: none !important;
}

.table thead th {
  border-bottom: 1px solid #14304c;
  font-size: 1.6rem;
  font-weight: 400 !important;
}